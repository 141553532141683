.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #000000;
  position: relative;
}
@media (min-width: 600px) {
  .root {
    height: auto;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 900px) {
  .root {
    width: 100%;
    min-width: 0;
  }
}
.innerFooterModule:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
@media (min-width: 1900px) {
  .innerFooterModule:global(.__wab_instance) {
    width: 80%;
    max-width: var(--token-c-29j4nMe);
  }
}
