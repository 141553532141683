.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 1024px) {
  .root {
    padding: 0px;
  }
}
.navbar:global(.__wab_instance) {
  max-width: 100%;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 2;
}
.footerModule:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (min-width: 600px) {
  .footerModule:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 900px) {
  .footerModule:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
