.root {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.span___2SCs {
  position: relative;
  height: auto;
  max-width: 100%;
}
.countdownText {
  position: relative;
}
.left {
  position: relative;
  height: auto;
  max-width: 100%;
}
.span__flrIk {
  position: relative;
  height: auto;
  max-width: 100%;
}
.yourLastChanceFor33Off {
  position: relative;
  height: auto;
  max-width: 100%;
}
.timer:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
